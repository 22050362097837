












import { defineComponent, toRef, getCurrentInstance } from '@vue/composition-api'

import { useThemeClass } from '../../composables'

import { stepsProps } from './Steps.hooks'
import { StepsProps, stepsThemeClassRegistry } from './Steps.contracts'

/**
 * Component capable to render `Steps` (stepper) element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Steps = defineComponent({
  name: 'Steps',
  props: stepsProps,

  setup (props: StepsProps) {
    const internalInstance = getCurrentInstance()

    if (!internalInstance) {
      throw new Error('Steps molecule: Cannot locate instance of the component.')
    }

    const tag: string = String(internalInstance.type._componentTag)
    return useThemeClass(toRef(props, 'theme'), stepsThemeClassRegistry, tag)
  }
})
export default Steps
